<template>
  <div>
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              @click="addServiceSidebar = true"
            >
              + Tambah Data Service
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                class="invoice-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="rows"
        responsive
        :fields="columns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchQuery"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder">#{{ data.value }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(foto_nota)="data">
          <b-img
            :src="require(`@/assets/images/pages/${data.value}`)"
            height="125"
            alt="Responsive image"
          />
        </template>

        <template #cell(action)="data">

          <div class="text-nowrap">
            <b-button-group>
              <b-button
                :id="`service-row-${data.item.id}-edit-icon`"
                size="sm"
                variant="warning"
                @click="editService(data.item, true)"
              >
                Edit
              </b-button>

              <b-button
                :id="`service-row-${data.item.id}-jual-icon`"
                size="sm"
                variant="danger"
                @click="removeData(data.item.id)"
              >
                Hapus
              </b-button>
            </b-button-group>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <!-- <span class="text-muted">Showing entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalServices"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <AddContent
      v-model="addServiceSidebar"
      :service="dataEditService"
      @service-sidebar-change="editService"
      @submit-data-service="submitData"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BImg,
  BPagination, BButtonGroup,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import AddContent from './Add.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BButtonGroup,
    BImg,

    AddContent,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'ID',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Tanggal Service',
          key: 'tanggal_service',
          sortable: true,
        },
        {
          label: 'Keterangan',
          key: 'keterangan',
          sortable: true,
        },
        {
          label: 'Biaya',
          key: 'biaya',
          sortable: true,
        },
        // {
        //   label: 'Foto Nota',
        //   key: 'foto_nota',
        //   sortable: true,
        // },
        {
          label: 'Actions',
          key: 'action',
        },
      ],
      perPage: 5,
      totalServices: 0,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      rowsData: [],
      searchTerm: '',
      statusOptions: [
        'Aktif',
        'Tidak Aktif',
      ],
      addServiceSidebar: false,
      dataEditService: {
        id: null,
        name: null,
        seat: null,
        seat_conf: null,
        status: 0,
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    rows() {
      if (this.statusFilter !== null) {
        const statusFilter = this.statusFilter === 'Aktif' ? 1 : 2

        return this.rowsData.filter(data => data.status === statusFilter)
      }
      return this.rowsData
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get(`/record-service-bis/bis/${this.$route.params.id}`).then(response => {
        this.rowsData = response.data.data
        this.totalServices = response.data.data.length
      })
    },
    editService(data, value) {
      this.dataEditService = data
      this.addServiceSidebar = value
    },
    submitData(data) {
      if (data.id === null) {
        const formData = new FormData()
        formData.append('keterangan', data.keterangan)
        formData.append('biaya', data.biaya)
        formData.append('foto_nota', data.foto_nota)
        formData.append('id_bis', this.$route.params.id)
        this.$http.post('/record-service-bis', formData).then(response => {
          if (response.data.status === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'BellIcon',
                variant: 'success',
              },
            })

            this.getData()
          }
        })
        this.dataEditService = {
          id: null,
          keterangan: null,
          biaya: null,
          foto_nota: null,
        }
      } else {
        const formData = new FormData()
        formData.append('id', data.id)
        formData.append('keterangan', data.keterangan)
        formData.append('biaya', data.biaya)
        formData.append('foto_nota', data.foto_nota)
        formData.append('id_bis', this.$route.params.id)
        formData.append('_method', 'PUT')
        this.$http.post(`/record-service-bis/${data.id}`, formData).then(response => {
          if (response.data.status === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'BellIcon',
                variant: 'success',
              },
            })

            this.getData()
          }
        })
        this.dataEditService = {
          id: null,
          keterangan: null,
          biaya: null,
          foto_nota: null,
        }
      }
      this.addServiceSidebar = false
    },
    removeData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`record-service-bis/${id}`).then(response => {
            if (response.data.status === true) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              this.getData()
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
</style>
