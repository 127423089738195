var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"add-bus-sidebar","sidebar-class":"sidebar-lg","bg-variant":"white","visible":_vm.addServiceSidebar,"right":"","no-header":"","backdrop":""},on:{"hidden":function (val) { return _vm.changeValue(val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.serviceLocal.id > 0 ? 'Edit Bus' : 'Add Bus')+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules",staticClass:"px-2"},[_c('b-form',[_c('b-row',{staticClass:"px-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal","label-for":"tanggal"}},[_c('validation-provider',{attrs:{"name":"Tanggal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"tanggal","state":errors.length > 0 ? false:null},model:{value:(_vm.serviceLocal.tanggal_service),callback:function ($$v) {_vm.$set(_vm.serviceLocal, "tanggal_service", $$v)},expression:"serviceLocal.tanggal_service"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Keterangan","label-for":"keterangan"}},[_c('validation-provider',{attrs:{"name":"Keterangan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"keterangan","state":errors.length > 0 ? false:null,"placeholder":"Keterangan"},model:{value:(_vm.serviceLocal.keterangan),callback:function ($$v) {_vm.$set(_vm.serviceLocal, "keterangan", $$v)},expression:"serviceLocal.keterangan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Biaya","label-for":"biaya"}},[_c('validation-provider',{attrs:{"name":"Biaya","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"biaya","state":errors.length > 0 ? false:null,"placeholder":"Biaya"},model:{value:(_vm.serviceLocal.biaya),callback:function ($$v) {_vm.$set(_vm.serviceLocal, "biaya", $$v)},expression:"serviceLocal.biaya"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.serviceLocal.id > 0 && _vm.serviceLocal.foto)?_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('label',{attrs:{"for":"preview-image"}},[_vm._v("Preview Foto Sebelumnya: ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-img',{staticClass:"mb-2",attrs:{"id":"preview-image","width":"200","src":_vm.serviceLocal.foto,"fluid":"","alt":"Preview Image"}})],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Foto Nota","label-for":"foto-nota"}},[_c('validation-provider',{attrs:{"name":"Foto Nota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"foto-nota","accept":"image/*","state":errors.length > 0 ? false:null,"placeholder":"Pilih File"},model:{value:(_vm.serviceLocal.foto_nota),callback:function ($$v) {_vm.$set(_vm.serviceLocal, "foto_nota", $$v)},expression:"serviceLocal.foto_nota"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.changeValue(false)}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }