<template>
  <div>
    <b-sidebar
      id="add-bus-sidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      :visible="addServiceSidebar"
      right
      no-header
      backdrop
      @hidden="(val) => changeValue(val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ serviceLocal.id > 0 ? 'Edit Bus' : 'Add Bus' }}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <validation-observer
          ref="simpleRules"
          class="px-2"
        >
          <b-form>
            <b-row class="px-2">
              <b-col md="12">
                <b-form-group
                  label="Tanggal"
                  label-for="tanggal"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="tanggal"
                      v-model="serviceLocal.tanggal_service"
                      :state="errors.length > 0 ? false:null"
                      class="mb-1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Keterangan"
                  label-for="keterangan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Keterangan"
                    rules="required"
                  >
                    <b-form-input
                      id="keterangan"
                      v-model="serviceLocal.keterangan"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Keterangan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Biaya"
                  label-for="biaya"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Biaya"
                    rules="required"
                  >
                    <b-form-input
                      id="biaya"
                      v-model="serviceLocal.biaya"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Biaya"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="serviceLocal.id > 0 && serviceLocal.foto"
                md="12"
              >
                <b-row>
                  <b-col md="12">
                    <label for="preview-image">Preview Foto Sebelumnya: </label>
                  </b-col>
                  <b-col md="12">
                    <b-img
                      id="preview-image"
                      class="mb-2"
                      width="200"
                      :src="serviceLocal.foto"
                      fluid
                      alt="Preview Image"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Foto Nota"
                  label-for="foto-nota"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Foto Nota"
                    rules="required"
                  >
                    <b-form-file
                      id="foto-nota"
                      v-model="serviceLocal.foto_nota"
                      accept="image/*"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Pilih File"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="submit()"
                >
                  Submit
                </b-button>
                <b-button
                  variant="outline-secondary"
                  @click="changeValue(false)"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BButton, BCol,
  BRow, BSidebar, BFormFile, BFormDatepicker, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BCol,
    BRow,
    BSidebar,
    BFormFile,
    BFormDatepicker,
    BImg,
  },
  model: {
    prop: 'addServiceSidebar',
  },
  props: {
    addServiceSidebar: {
      type: Boolean,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      serviceLocal: {
        id: null,
        tanggal_service: null,
        keterangan: null,
        biaya: null,
        foto: null,
        foto_nota: null,
      },
      options: [
        { value: 0, text: 'Tidak Aktif' },
        { value: 1, text: 'Aktif' },
      ],
    }
  },
  watch: {
    service() {
      this.resetTaskLocal()
    },
  },
  methods: {
    resetTaskLocal() {
      this.serviceLocal = {
        id: this.service.id,
        tanggal_service: this.service.tanggal_service,
        keterangan: this.service.keterangan,
        biaya: this.service.biaya,
        foto: this.service.keuangan.foto_nota,
      }
    },
    changeValue() {
      const data = {
        id: null,
        tanggal_service: null,
        keterangan: null,
        biaya: null,
        foto_nota: null,
      }
      this.$emit('service-sidebar-change', data, false)
    },
    submit() {
      if (this.serviceLocal.id > 0) {
        this.$emit('submit-data-service', this.serviceLocal)
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$emit('submit-data-service', this.serviceLocal)
          }
        })
      }
    },
  },
}
</script>
<style>
.b-sidebar-body {
  overflow: hidden !important;
}
</style>
